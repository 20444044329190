<template>
  <div :class="{ 'dark-theme': isDarkTheme }">
    <div class="modalOpen" v-if="modalOpen">
      <div class="innerModal">
        <div class="modalHeader">
          <span>Dodaj na seznam</span>
          <div @click="newItem">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="currentColor"
              class="bi bi-x"
              viewBox="0 0 16 16"
            >
              <path
                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"
              />
            </svg>
          </div>
        </div>
        <div class="modalContent">
          <div class="inputGroup">
            <input type="text" v-model="itemText" />
            <label for="name">Naziv</label>
          </div>
        </div>
        <div class="modalFooter">
          <button class="buton" @click="insertItem">Dodaj</button>
        </div>
      </div>
    </div>
    <template v-if="cards.length > 0">
      <div class="card-container">
        <div
          @click="handleClick(card)"
          class="cards"
          :class="{
            'light-theme-cards': !isDarkTheme,
            'dark-theme-cards': isDarkTheme,
            'done-card': card.isDone === 1,
          }"
          v-for="card in cards"
          :key="card.id"
          :data="card"
        >
          <span>{{ card.title }}</span>
        </div>
      </div>
    </template>
    <template v-else>
      <p style="margin-top: 10px">Seznam je prazen</p>
    </template>
    <div class="butto" @click="newItem">
      <span>
        Izdelek
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
        >
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path
            fill="currentColor"
            d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z"
          ></path>
        </svg>
      </span>
    </div>
  </div>
</template>

<script>
import { phpIp } from "../store/global";
export default {
  data() {
    return {
      modalOpen: false,
      itemText: "",
      cards: [],
      phpIp: phpIp,
    };
  },
  computed: {
    isDarkTheme() {
      return this.$root.isDarkTheme;
    },
  },
  methods: {
    newItem() {
      this.modalOpen = !this.modalOpen;
    },
    handleClick(card) {
      card.isDone = 1;
      this.cards = [...this.cards];
      setTimeout(() => {
        this.deletemItem(card);
      }, 500);
    },
    async deletemItem(card) {
      try {
        const cardId = card.id;
        const response = await fetch(
          this.phpIp + `completeItem.php?id=${cardId}`,
          {
            method: "GET",
          }
        );
        if (!response.ok) {
          throw new Error(`Failed to fetch data. Status: ${response.status}`);
        }

        this.getItems();
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    },
    async insertItem() {
      try {
        const response = await fetch(this.phpIp + "insertItem.php", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            title: this.itemText,
          }),
        });

        if (!response.ok) {
          throw new Error(`Failed to fetch data. Status: ${response.status}`);
        }

        const data = await response.json();

        // this.modalOpen = !this.modalOpen;
        this.itemText = "";
        this.getItems();
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    },
    async getItems() {
      try {
        const response = await fetch(this.phpIp + "getItems.php", {
          method: "GET",
        });

        if (!response.ok) {
          throw new Error(`Failed to fetch data. Status: ${response.status}`);
        }

        const data = await response.json();
        this.cards = data;
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    },
  },
  created() {
    this.getItems();
  },
};
</script>

<style>
.modalOpen {
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  padding-top: 30%;
  background-color: #33333369;
  color: #333 !important;
}
.innerModal {
  width: 350px;
  max-height: 230px;
  background-color: #e8e8e8;
  border-radius: 10px;
  border: 1px solid #dddddd;
  z-index: 1;
}
.modalHeader {
  border-bottom: 1px solid #b9b9b9;
  font-size: 18px;
  padding: 10px 10px;
  display: flex;
  justify-content: space-between;
}
.modalContent {
  padding: 10px;
}
.modalFooter {
  border-top: 1px solid #b9b9b9;
  padding: 10px 10px;
  display: flex;
  justify-content: end;
}
.buton {
  border: 2px solid #41b3d3;
  background-color: #41b3d3;
  padding: 10px;
  border-radius: 10px;
  color: #fff;
}
.card-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  gap: 20px;
  padding: 20px 5px;
}
.done-card {
  background-color: rgba(40, 165, 40, 0.8) !important;
  box-shadow: 0px 5px 5px 1px rgba(40, 165, 40, 0.6) !important;
  border: 1px solid rgba(40, 165, 40, 0.1) !important;
}
.cards {
  height: 100px;
  font-size: 18px;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}
.light-theme-cards {
  border: 1px solid #f7f7f7;
  background-color: #f2f2f2;
  box-shadow: 0px 5px 5px 1px #e8e8e8;
}
.dark-theme-cards {
  border: 1px solid #6e6e6e;
  background-color: #6e6e6e;
  box-shadow: 0px 5px 5px 1px #111;
}
.butto {
  position: fixed;
  bottom: 20px;
  right: 20px;
  border: 2px solid #41b3d3;
  background-color: #41b3d3;
  border-radius: 0.9em;
  padding: 0.8em 1.2em 0.8em 1em;
  font-size: 16px;
}

.butto span {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 600;
}
.inputGroup {
  font-family: "Segoe UI", sans-serif;
  margin: 1em 0 1em 0;
  max-width: 190px;
  position: relative;
}

.inputGroup input {
  font-size: 100%;
  padding: 0.8em;
  outline: none;
  border: 2px solid rgb(200, 200, 200);
  background-color: transparent;
  border-radius: 20px;
  width: 100%;
}

.inputGroup label {
  font-size: 100%;
  position: absolute;
  left: 0;
  padding: 0.8em;
  margin-left: 0.5em;
  pointer-events: none;
  transition: all 0.3s ease;
  color: rgb(100, 100, 100);
}

.inputGroup :is(input:focus, input:valid) ~ label {
  transform: translateY(-50%) scale(0.9);
  margin: 0em;
  margin-left: 1.3em;
  padding: 0.4em;
  background-color: #e8e8e8;
}

.inputGroup :is(input:focus, input:valid) {
  border-color: #41b3d3;
}
</style>
