<template>
  <div class="containerr" :class="{ 'dark-theme': isDarkTheme }">
    <div
      class="nav justify-content-between sticky-top"
      :class="{
        'light-theme-nav': !isDarkTheme,
        'dark-theme-nav': isDarkTheme,
      }"
    >
      <div class="nav-menu">
        <input id="toggleChecker" type="checkbox" v-model="isMenuVisible" />
        <label id="togglerLable" for="toggleChecker">
          <div class="checkboxtoggler">
            <div class="line-1"></div>
            <div class="line-2"></div>
            <div class="line-3"></div>
          </div>
        </label>
        <div
          class="nav-menu-hidden"
          :style="{ width: isMenuVisible ? '250px' : '0px' }"
        >
          <router-link to="/" class="nav-router" @click="handleMenu"
            >KOŠARICA</router-link
          >
          <router-link to="/about" class="nav-router" @click="handleMenu"
            >RAČUNI</router-link
          >
        </div>
      </div>
      <img
        src="./assets/logo_transparent_round.png"
        alt="logo"
        width="60"
        height="60"
      />
      <label class="switch">
        <input type="checkbox" @click="getStatus" v-model="isDarkTheme" />
        <span class="slider"></span>
      </label>
    </div>
    <router-view />
  </div>
</template>

<script>
import { phpIp } from "./store/global";
export default {
  data() {
    return {
      isDarkTheme: true,
      isMenuVisible: false,
      phpIp: phpIp,
    };
  },
  methods: {
    handleMenu() {
      this.isMenuVisible = !this.isMenuVisible;
    },
  },
};
</script>

<style>
.containerr {
  min-height: 100vh;
}
.light-theme {
  background-color: #fff;
  color: #333;
}
.dark-theme {
  background-color: #333;
  color: #fff;
}
.nav {
  padding: 10px;
  box-shadow: 0px 0px 5px 0px #6d6d6d;
}
.light-theme-nav {
  background-color: #e8e8e8;
}
.dark-theme-nav {
  background-color: #3d3d3d;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
/* The switch - the box around the slider */
.switch {
  align-self: center;
  display: block;
  --width-of-switch: 3.5em;
  --height-of-switch: 2em;
  /* size of sliding icon -- sun and moon */
  --size-of-icon: 1.4em;
  /* it is like a inline-padding of switch */
  --slider-offset: 0.3em;
  position: relative;
  width: var(--width-of-switch);
  height: var(--height-of-switch);
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f4f4f5;
  transition: 0.4s;
  border-radius: 30px;
}

.slider:before {
  position: absolute;
  content: "";
  height: var(--size-of-icon, 1.4em);
  width: var(--size-of-icon, 1.4em);
  border-radius: 20px;
  left: var(--slider-offset, 0.3em);
  top: 50%;
  transform: translateY(-50%);
  background: linear-gradient(40deg, #ff008059, #ff8c00 70%);
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #303136;
}

input:checked + .slider:before {
  left: calc(100% - (var(--size-of-icon, 1.4em) + var(--slider-offset, 0.3em)));
  background: #303136;
  /* change the value of second inset in box-shadow to change the angle and direction of the moon  */
  box-shadow: inset -3px -2px 5px -2px #8983f7, inset -10px -4px 0 0 #a3dafb;
}

.checkboxtoggler {
  width: 3em;
  display: flex;
  flex-direction: column;
  gap: 0.7em;
  cursor: pointer;
  z-index: 1;
  position: relative;
}

.line-1 {
  background: #41b3d3;
  height: 0.3em;
  border-radius: 10em;
  transition-duration: 500ms;
}

.line-2 {
  background: #41b3d3;
  height: 0.3em;
  border-radius: 10em;
  transition-duration: 500ms;
}

.line-3 {
  background: #41b3d3;
  height: 0.3em;
  border-radius: 10em;
  transition-duration: 500ms;
}

#toggleChecker {
  height: 3em;
  width: 100%;
  display: none;
}

#toggleChecker:checked + #togglerLable .checkboxtoggler .line-1 {
  -webkit-transform: rotate(45deg) translateY(0.7em) translateX(0.7em);
  -ms-transform: rotate(45deg) translateY(0.7em) translateX(0.7em);
  transform: rotate(45deg) translateY(0.7em) translateX(0.7em);
}

#toggleChecker:checked + #togglerLable .checkboxtoggler .line-2 {
  -webkit-transform: rotate(-45deg) translateY(0em) translateX(0.1em);
  -ms-transform: rotate(-45deg) translateY(0em) translateX(0.1em);
  transform: rotate(-45deg) translateY(0em) translateX(0.1em);
}

#toggleChecker:checked + #togglerLable .checkboxtoggler .line-3 {
  transform: scaleX(0);
  transform-origin: left;
}

.nav-menu {
  position: relative;
}

.nav-menu-hidden {
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  transition: 0.5s;
  overflow-x: hidden;
  background-color: #bbb;
  padding-top: 60px;
  text-align: left;
}

.nav-router {
  padding-top: 20px;
  text-decoration: none;
  color: #303136;
  font-weight: bold;
  display: block;
  max-width: 60%;
  margin-left: 20px;
}
</style>
